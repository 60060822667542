.ant-layout-sider {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.ant-menu > li.ant-menu-item,
.ant-menu > li.ant-menu-submenu > .ant-menu-submenu-title {
  height: 55px !important;
}

.ant-menu > li.ant-menu-item {
  margin: 0 !important;
}

.ant-menu.ant-menu-inline-collapsed > li.ant-menu-item {
  display: flex !important;
  align-items: center !important;
  background-color: #fff !important;
  width: 55px !important;
  border-radius: 50% !important;
  padding-left: 16px !important;
  margin-bottom: 10px !important;
}

.ant-menu.ant-menu-inline-collapsed > li.ant-menu-item.ant-menu-item-selected {
  background-color: #fff2e6 !important;
  border: 1px solid #fe5000 !important;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #fff !important;
}

.side-wrapper .sider {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 10px;
}

.side-wrapper:hover .sider {
  position: absolute;
  min-width: 200px;
  height: -webkit-fill-available;
  z-index: 9999;
  background-color: #fff;
  border-radius: 0px 20px 20px 0px;
}

.side-wrapper li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.side-wrapper:hover li {
  width: auto;
  border-radius: 10px;
  padding-left: 15px;
}

.side-wrapper:hover li.active,
.side-wrapper:hover li:hover {
  background: linear-gradient(90deg, rgba(30, 49, 246, 1) 32%, rgba(0, 212, 255, 1) 100%);
  color: #fff;
  border: none;
}

.side-wrapper li.active,
.side-wrapper li:hover {
  background-color: #1e31f6;
  border: 1px solid #1e31f6;
}

.side-wrapper li.active path:not(.fill),
.side-wrapper li:hover path:not(.fill),
.side-wrapper li.active circle,
.side-wrapper li:hover circle {
  stroke: #fff;
}

.side-wrapper li.active path.fill,
.side-wrapper li:hover path.fill {
  fill: #fff;
}

.side-wrapper:hover .sider li.submenu {
  margin-left: 15px;
}

.side-wrapper .text,
.side-wrapper .collapse {
  display: none;
}

.side-wrapper .sider li .content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.side-wrapper:hover .sider li .content {
  justify-content: space-between;
  width: 85%;
}

.side-wrapper .sider li .content .details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.side-wrapper .sider li .content .details .icon {
  line-height: 1;
  /* margin-left: 7px */
  margin-top: 7px;
}

.side-wrapper .sider li .content .details .label {
  line-height: 1;
  /* margin-left: 7px */
  margin-top: 9px;
}

.side-wrapper .sider li .content .collapse {
  color: #000 !important;
  font-size: 12px;
}

.side-wrapper:hover .text,
.side-wrapper:hover .collapse {
  display: flex;
}

.side-wrapper .holder {
  width: 60px;
  display: none;
}

.side-wrapper:hover .holder {
  display: block;
}

.sider a {
  text-decoration: none; /* Removes underline */
  color: inherit;        /* Inherits the text color from the parent element */
  display: block;
  align-items: center; /* Ensures text and icon align properly */

}

.sider a:hover {
  color: #fff; /* Optional: Add a hover effect for better UX */
}

.sider {
  list-style-type: none;
  padding: 0;
}

.sider li {
  display: inline-block;
  /* margin-right: 20px; */
}






