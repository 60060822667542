/*@tailwind base;
@tailwind components;
@tailwind utilities;*/
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* Novelis App Components CSS */
.outerCardHeader {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.innerCardHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.justifyRight {
  display: flex;
  justify-content: flex-end;
}
.justifyLeft {
  display: flex;
  justify-content: flex-start;
}
/* antD select css starts*/
.ant-select-multiple .ant-select-selection-item {
  border-radius: 15px;
  border: 3px solid rgba(176, 40, 26, 0.82);
}
/* antD select css ends*/

/* antD ant-popover css starts*/
.ant-popover {
  border: 1px solid #d9d9d9; /* Adjust this value as needed */
  border-radius: 8px;
}
/* antD ant-popover css ends*/

.ant-card {
  border-radius: 25px;
  padding: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* antD table css starts*/
.ant-table-tbody > tr {
  background-color: #F4F7FC;
}

.ant-table-thead > tr > th {
  background-color: #FFF !important;
  border-bottom: none !important;
}

.ant-table-thead > tr > td {
  background-color: #FFF !important;
  border-bottom: none !important;
}


.ant-table-tbody > tr > td:first-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.ant-table-tbody > tr > td:last-child {
  border-top-right-radius: 15px !important; /* Adjust the value as needed */
  border-bottom-right-radius: 15px !important;
}

.ant-table-thead > tr > th:first-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.ant-table-thead > tr > th:last-child {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.ant-table {
  background: transparent !important;
}

.ant-table table {
  border-spacing: 0 5px;
  /*background: transparent !important;*/
}

.ant-table .ant-table-container {
  overflow: auto;
}

/* Ant D table css ends*/

/* antD datepicker css starts*/
.ant-picker {
  padding: 4px 8px 4px !important;
}

.ant-picker-range .ant-picker-range-separator {
  padding: 0 4px !important;
}

/* antD datepicker css ends*/

.ant-select-arrow-loading {
  color: rgb(50 78 255) !important;
  font-size: 16px !important;
}

/* antD tabs css starts*/

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-tab {
  background-color: transparent !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff !important;
}

.ant-tabs-content-holder {
  background-color: #fff !important;
  padding: 10px !important;
  margin-left: 1px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.statisticsCard {
  font-size: 13px;
  font-weight: 500;
  color: #151111;
}
/* antD tabs css ends*/
