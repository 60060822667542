/*:where(.css-dev-only-do-not-override-vj0ngj).ant-layout {
    background-color: #f6fbf8;
}*/

.page-loading {
    background-color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.before-login-main-content {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #fff;
}

.after-login-main-content {
    padding: 12px;
    min-height: calc(100vh - 112px);
}

/* START: Header and Logo */
.site-header {
    padding: 0;
    background-color: #EFF2F7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    width: 100%;
    z-index: 200;
    box-shadow: 0 4px 2px -2px #E4E9F3;
    border-radius: 20px;
}

.logo-font {
    margin-top: 5px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #151111;
}

.logo-image {
    height: 2rem;
    margin-right: 0.5rem;
    margin-left: 1.5rem;
    margin-top: 5px;
}
/* END: Header and Logo */




/* START: Logo small and large */
/*
<div className='demo-logo-vertical'>
                <img src='/images/app_logo.png' alt='logo' width='32' className='logo-large' />
                <img src='/images/logo_icon.png' alt='logo' width='160' className='logo-small' />
            </div>


.demo-logo-vertical {
    padding: 10px;
}

.demo-logo-vertical .logo-small {
    display: none;
    border-radius: 8px;
}

.demo-logo-vertical .logo-large {
    border-radius: 8px;
}

.ant-layout-sider-collapsed .demo-logo-vertical {
    text-align: center;
}

.ant-layout-sider-collapsed .demo-logo-vertical .logo-small {
    display: inline-block;
    height: 32px;
    width: auto;
}

.ant-layout-sider-collapsed .demo-logo-vertical .logo-large {
    display: none;
}*/


/* START: Sidebar menu CSS */
/*.sidebar-menu .ant-menu-item {
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 0;
}

.sidebar-menu:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-light .ant-menu-item-selected,
.sidebar-menu:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-light
> .ant-menu
.ant-menu-item-selected {
    background-color: #0e8744;
    color: #ffffff;
    border-radius: 0 1rem 4rem 0;
}

:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item
.ant-menu-item-icon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.ant-menu-item-icon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.ant-menu-item-icon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.ant-menu-item-icon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item
.anticon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.anticon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon
+ span,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon
+ span {
    display: none;
}

:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed > .ant-menu-item,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-15tp0wl).ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title {
    text-align: center;
}*/

/* END: Sidebar menu CSS */
